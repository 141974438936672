#contact{
  padding-bottom: 2em;
  overflow: hidden;
  padding-top: 2em;
  background-color: #C8E0EB;
}

#contact h2{
  margin:0;
  padding:1em;
  font-size: 3em;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-decoration: underline;
}

form{
  width:40%;
  border: 2px solid #9E8668;
  border-radius: 5px;
  margin: 1em auto;
  background-color: #78929E;
}

form input, form textarea{
  display:block;
  margin: 1em auto;
  width: 70%;
  height:30px;
  border:1px solid black;
  border-radius: 5px;
  opacity: 1;
  font-size: 1em;
}

button{
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 400;
}

label{
  font-family: 'Montserrat', sans-serif;
}

form textarea{
  height:50px;
}

form label{
  font-size:1.3em;
}

form .pair{
  margin-top:2em;
}

form button{
  margin: .5em auto 1.5em auto;
  font-size: 2em;
  background-color: #B48C1D;
  border: 1px solid black;
}

@media (max-width:800px){
  form{
    width: 60%
  }
}

@media (max-width:800px){
  form{
    width: 80%
  }
}