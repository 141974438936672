header{
  position:sticky;
  top:0;
  margin:0;
  height:70px;
  line-height: 70px;
  width:100%;
  background-color: black;
  opacity:.8;
  display:flex;
  justify-content: flex-end;
  z-index: 10;
}

header a{
  font-size: 1.5em;
  text-decoration: none;
  color:white;
  margin-right: 4em;
  font-family: 'Montserrat', sans-serif;
}

.ham-icon {
  margin: auto 2em auto 2em;
  width: 40px;
  cursor: pointer;
  display:none
}

.ham-icon:after, 
.ham-icon:before, 
.ham-icon div {
  background-color: white;
  border-radius: 3px;
  content: '';
  display: block;
  height: 5px;
  margin: 7px 0;
  transition: all .2s ease-in-out;
}

.clicked-ham:before {
  transform: translateY(12px) rotate(135deg);
}

.clicked-ham:after {
  transform: translateY(-12px) rotate(-135deg);
}

.clicked-ham div {
  transform: scale(0);
}

.ham-menu{
  position:absolute;
  right:0;
  top:60px;
  border-left:1px solid black;
  border-bottom: 1px solid black;
  background:black;
  opacity:.9;
  z-index: 10;
  animation: slideIn .6s ease-out;
}

.ham-menu a{
  margin: 0 .8em;
  text-align: right;
}

@keyframes slideIn{
  from {
    right:-160px
  }
  to{
    right:0
  }
}

.ham-menu a{
  display:block;
}

.underline {
  position: relative;
  color: white;
  text-decoration: none;
}

.underline:hover {
  color: white;
}

.underline:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 10px;
  left: 0;
  background-color: white;
  visibility: hidden;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.underline:hover:before {
  visibility: visible;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

@media (max-width:700px){
  .underline{
    display:none;
  }

  .ham-icon{
    display:block;
  }

  header{
    height:60px;
    line-height: 60px;
  }
}