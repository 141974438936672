#hero{
  height: calc(100vh - 70px)
}

.hero-img{
  height: 100vh;
  width:100%;
  background-image:url('../images/hero1.jpg');
  background-size: cover;
  background-position: center;
  overflow: hidden;
  z-index: 1;
  position:relative
}

.hero-img::after {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-image: linear-gradient(
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.6)
  );
  z-index: -1;
  animation: gradient 1.2s 1.2s ease-out both
}

@keyframes gradient{
  from{
    opacity:0
  }
  to{
    opacity:1
  }}

.hero-img img{
  border-radius: 100%;
  height:53%;
  margin-top: 4%;
}

.hero-img h1{
  font-family: helvetica;
  font-family: 'Merriweather Sans', sans-serif;
  color:white
}

.hero-img h2{
  font-family: times;
  color:white;
  width:100%;
  font-family: 'Montserrat', sans-serif;
}

@media (max-width:1000px){
  .hero-img img{
    height:60%;
    margin-top:7%;
  }
}

@media (max-width:700px){
  #hero{
    height: calc(100vh - 60px)
  }

  .hero-img img{
    height:45%;
    margin-top:19%;
  }
}

@media (max-width:500px){
  .hero-img img{
    height:40%;
    margin-top:17%;
  }

  .hero-img h1{
    margin-top:3em;
    margin-bottom: 2em;;
  }
}