#about{
  overflow: hidden;
  padding-top: 2em;
}

#about h2{
  margin:0;
  padding:1em;
  font-size: 3em;
  font-family: 'Raleway', sans-serif;
  font-weight: 700;
  text-decoration: underline;
}

.about{
  display:flex;
  justify-content: center;
}

.about-left{
  width:60%
}

.about-right{
  width:40%;
}

.about-left{
  text-align:right;
}

.about-left p{
  margin-left: 40%;
  margin-right: 2em;
  font-family: 'Montserrat', sans-serif;
  font-weight: 500;
}

.about-right img{
  width:30px;
  height:30px;
  margin: auto 1em
}

.about-right{
  text-align: left;
  border-left: 1px solid black;
  margin-top: .8em;
}

.channel h4{
  margin: auto 0;
  font-family: 'Merriweather Sans', sans-serif;
  font-weight: 600;
}

.channel a{
  text-decoration:none;
  color:black;
  text-align: left;
  height:40px;
  line-height: 40px;
  display:flex;
  justify-content: left;
}

.skills{
  margin-top: 2em;
  padding-bottom: 2em;
  font-family: 'Merriweather Sans', sans-serif;
}

.skill-icons{
  display:flex;
  justify-content: center;
  flex-wrap: wrap;
}

.skill-icons img{
  height:90px;
  width:90px;
  margin:0 1em
}

@media (max-width:900px){

  .about-left p{
    margin-left:10%;
    margin-right:.5em;
  }

  .skill-icons img{
    height:70px;
    width:70px;
    margin:0 .7em
  }
}

@media (max-width:750px){
  .about{
    display:block;
  }

  .about-left{
    width:80%;
    margin:.5em auto;
    text-align: justify;
  }

  .about-right{
    width:40%;
    margin: .5em auto;
    border-left:none
  }

  .channel{
    margin:.5em auto;
  }

  .skill-icons{
    width:80%;
    margin: 0 auto;
  }

  .skill-icons img{
    margin:.5em
  }
}

@media (max-width:550px){
  .about-right{
    width:60%;
  }
}

  @media (max-width:450px){
    .about-right{
      width:80%;
    }
}